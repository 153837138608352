import home from './home/routes'
import auth from './auth/routes'
//import search from './search/routes'
//import gallery from './gallery/routes'
//import servies from './servies/routes'
//import crowdFunding from './crowdFunding/routes'
//import forum from './forum/routes'
//import cart from './cart/routes'
import dashboard from './dashboard/routes'
import myProfile from './myProfile/routes'
import myTasks from './myTasks/routes'
import orders from './orders/routes'
import artLibrary from './artLibrary/routes'
//import photoLibrary from './photoLibrary/routes'
//import frameLibrary from './frameLibrary/routes'
//import designLibrary from './designLibrary/routes'
import exhibitions from './exhibitions/routes'
import refferals from './refferals/routes'
import adminCrowdFunding from './adminCrowdFunding/routes'
import settings from './settings/routes'

export default [
	...home, 
	...auth, 
	//...search,
	//...gallery, 
	//...servies , 
	//...crowdFunding, 
	//...forum, 
	//...cart,
	...dashboard,
	...myProfile,
	...myTasks,
	...orders,
	...artLibrary,
	//...photoLibrary,
	//...frameLibrary,
	//...designLibrary,
	...exhibitions,
	...refferals,
	...adminCrowdFunding,
	...settings,
];

 
