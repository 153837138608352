<template>
	<div class="bg-bgFooter w-full">
		<div class="w-full h-[560px] md:h-[300px] lg:h-[400px] flex 2xl:justify-center md:rounded-footerRadius shadow-footerShadow bg-bgReg px-4 lg:px-10">
			<div class="w-full h-[390px] md:flex xl:gap-1">
				<div class="md:w-[384px] h-[390px flex justify-center">
					<div class="flex flex-col space-y-1 lg:space-y-3">
						<div class="w-full flex justify-center">
							<div class="w-[210px] lg:w-[280px] xl:w-[320px] h-[70px] lg:h-[90px] xl:h-[120px] rounded-b-3xl xl:rounded-footerLogoBg shadow-footerBgShadow bg-white flex justify-center items-center">
								<img src="/images/logo.webp" class="w-[190px] lg:w-[240px] xl:w-[300px] h-[60px] lg:h-[70px] xl:h-[100px] shadow-footerLogoShadow rounded-full p-3 object-contain">
							</div>
						</div>
						<div class="flex flex-col justify-center">
							<lable class="text-center pt-5 pb-2 text-white uppercase font-semibold font-nunito">Sekai-Ichi</lable>
							<p class="text-white tracking-wide capitalize font-light text-[13px] lg:text-sm xl:text-base">
								<!-- is the company with the mission of developing an innovative Solutions for various problems in today's world.We encourage to make the things that change the way of life. -->{{$t('company-mission')}}
							</p>
							<div class="flex justify-center gap-4 pt-4 md:pt-8">
								<label class="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px] bg-white rounded-full flex justify-center items-center">
									<svg class="fill-current w-[16px] lg:w-[24px] lg:h-[26px]" fill="none" viewBox="0 0 24 26">
										<path fill="#000" d="M3.07372.445313C2.67951.445015 2.2891.522383 1.9248.673c-.3643.150616-.69536.37153-.97427.65012-.278907.27859-.500196.6094-.651226.97353-.15103.36413-.2288423.75445-.2289915 1.14866 0 1.65909 1.3443175 3.03409 2.9999975 3.03409 1.65796 0 3.00341-1.375 3.00341-3.03181.0003-.39416-.07708-.78451-.22771-1.14875-.15063-.36424-.37156-.69523-.65016-.97404-.27861-.27882-.60943-.500002-.97355-.650908C3.85817.522986 3.46788.445313 3.07372.445313ZM17.7237 8.39986c-2.5216 0-3.9636 1.31818-4.6568 2.62954h-.0727V8.75327H8.02486V25.4453h5.17844v-8.2625c0-2.1773.1636-4.2818 2.8579-4.2818 2.6557 0 2.6943 2.483 2.6943 4.4193v8.125h5.1785v-9.1682c0-4.4863-.9648-7.87724-6.2103-7.87724ZM.482812 8.75213V25.4453H5.66463V8.75213H.482812Z"/>
									</svg>
								</label>
								<label class="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px] bg-white rounded-full flex justify-center items-center">
									<svg class="fill-current w-[10px] lg:w-[14px] lg:h-[26px]" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.08333 8.77865V6.27865C9.08333 5.19531 9.33333 4.61198 11.0833 4.61198H13.25V0.445312H9.91667C5.75 0.445312 4.08333 3.19531 4.08333 6.27865V8.77865H0.75V12.9453H4.08333V25.4453H9.08333V12.9453H12.75L13.25 8.77865H9.08333Z" fill="black"/></svg>
								</label>
								<label class="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px] bg-white rounded-full flex justify-center items-center">
									<svg class="fill-current w-[16px] lg:w-[26px] lg:h-[21px]" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23 18.4453H20.5V7.50781L13 12.1953L5.5 7.50781V18.4453H3V3.44531H4.5L13 8.75781L21.5 3.44531H23M23 0.945312H3C1.6125 0.945312 0.5 2.05781 0.5 3.44531V18.4453C0.5 19.1084 0.763392 19.7442 1.23223 20.2131C1.70107 20.6819 2.33696 20.9453 3 20.9453H23C23.663 20.9453 24.2989 20.6819 24.7678 20.2131C25.2366 19.7442 25.5 19.1084 25.5 18.4453V3.44531C25.5 2.78227 25.2366 2.14639 24.7678 1.67755C24.2989 1.2087 23.663 0.945313 23 0.945312Z" fill="black"/></svg>
								</label>
							</div>
						</div>
					</div>					
				</div>

				<div class="w-full pt-5 pb-5 lg:pt-8 md:px-10 xl:px-20">
					<div class="flex w-full  h-[160px] lg:h-[220px] gap-6">
						<div class="md:w-4/12 space-y-3">
							<label class="text-xl lg:text-3xl text-white font-roboto">
							<!-- Products -->{{$t('products')}}
							</label>
							<div class="space-y-1 text-white flex flex-col text-sm lg:text-base">
								<a :href="plannerUrl" target= _blank class="font-light hover:underline">
								<!-- Smart Planner -->{{$t('smart-planner')}}
								</a>
								<a :href="recruitUrl" target= _blank class="font-light hover:underline">
								<!-- Smart Recruit -->{{$t('smart-recruit ')}}
								</a>
								<a :href="buyerBaseUrl" target= _blank class="font-light hover:underline">
								<!-- Smart Art -->{{$t('smart-art')}}
								</a>
								<label title="coming Soon..." class="font-light text-gray-400 cursor-not-allowed">
								<!-- Smart Trial -->{{$t('smart-trial')}}
								</label>
								<a href="#" class="font-semibold hover:underline"><!-- More... -->{{$t('more')}}
								</a>
							</div>
						</div>
						<div class="md:w-4/12 space-y-3">
							<label class="text-xl lg:text-3xl text-white font-roboto">
							<!-- Services -->{{$t('services')}}
							</label>
							<div class="space-y-1 text-white flex flex-col text-sm lg:text-base">
								<a title="coming Soon..." href="javascript:void(0)" class="font-light text-gray-400 cursor-not-allowed">
								<!-- Art Exhibitions -->{{$t('art-exhibitions')}}
								</a>
								<a title="coming Soon..." href="javascript:void(0)" class="font-light text-gray-400 cursor-not-allowed">
								<!-- Photo Exhibitions -->{{$t('photo-exhibition')}}
								</a>
								<a :href="buyerBaseUrl+'/buy-and-rent'" target= _blank class="font-light hover:underline">
								<!-- Buy/Rent -->{{$t('buy-rent ')}}
								</a>
								<a :href="buyerBaseUrl+'/sales-and-auction'" target= _blank class="font-light hover:underline">
								<!-- Sale/Auction -->{{$t('sale-auction ')}}
								</a>
								<a href="#" class="font-semibold hover:underline"><!-- More... -->{{$t('more')}}</a>
							</div>
						</div>
						<div class="md:w-4/12 space-y-3">
							<label class="text-xl lg:text-3xl text-white font-roboto">
							<!-- About us -->{{$t('about-us ')}}
							</label>
							<div class="space-y-1 text-white flex flex-col text-sm lg:text-base">
								<a href="#" class="font-light hover:underline">
								<!-- Company -->{{$t('company')}}</a>
								<a href="#contact-scroll" class="font-light hover:underline">
								<!-- Contact -->{{$t('contacts ')}}
								</a>
								<a href="#feedback-scroll" class="font-light hover:underline">
									<!-- Feedback -->{{$t('feedback ')}}
								</a>
							</div>
						</div>
					</div>
					<div class="flex space-x-8 w-full gap-4">
						<div class="w-6/12">
							<div class="flex gap-2 lg:gap-6">
								<span class="w-[38px] lg:w-[48px] flex items-center">
									<svg class="fill-current w-[15px] lg:w-[25px] lg:h-[30px]" fill="none" viewBox="0 0 32 41"><path fill="#fff" fill-rule="evenodd" d="M16 4.94531c-6.62742 0-12 5.37259-12 11.99999 0 2.0558.72789 4.3536 1.96301 6.7356 1.22613 2.3647 2.88418 4.6828 4.58089 6.743 1.6917 2.0543 3.3891 3.8128 4.6657 5.059.287.2802.5521.5339.7904.7586.2383-.2247.5034-.4784.7904-.7586 1.2766-1.2462 2.974-3.0047 4.6657-5.059 1.6967-2.0602 3.3548-4.3783 4.5809-6.743C27.2721 21.2989 28 19.0011 28 16.9453c0-6.6274-5.3726-11.99999-12-11.99999Zm0 33.99999c-1.3016 1.5185-1.3024 1.5179-1.3024 1.5179l-.0042-.0037-.0101-.0087-.035-.0303c-.0299-.026-.0727-.0634-.1276-.1117-.1097-.0966-.2676-.2371-.4669-.4181-.3984-.3618-.9629-.8861-1.6384-1.5455-1.3484-1.3163-3.15105-3.1828-4.95926-5.3785-1.80333-2.1897-3.64528-4.7467-5.04415-7.4445C1.02211 22.8417 0 19.8896 0 16.9453 0 8.10876 7.16344.945312 16 .945312c8.8366 0 16 7.163448 16 15.999988 0 2.9443-1.0221 5.8964-2.412 8.5769-1.3989 2.6978-3.2408 5.2548-5.0441 7.4445-1.8083 2.1957-3.6109 4.0622-4.9593 5.3785-.6755.6594-1.24 1.1837-1.6384 1.5455-.1993.181-.3572.3215-.4669.4181-.0549.0483-.0977.0857-.1276.1117l-.035.0303-.0101.0087-.0032.0027s-.0018.0016-1.3034-1.5169Zm0 0-1.3024 1.5179c.749.6419 1.855.6426 2.604.0006L16 38.9453Zm0-26c-2.2091 0-4 1.7909-4 4 0 2.2092 1.7909 4 4 4 2.2091 0 4-1.7908 4-4 0-2.2091-1.7909-4-4-4Zm-8 4c0-4.4183 3.5817-7.99999 8-7.99999s8 3.58169 8 7.99999-3.5817 8-8 8-8-3.5817-8-8Z" clip-rule="evenodd"/>
									</svg>
								</span>
								<div class="flex flex-col space-y-1 font-semibold">
									<label class="text-white uppercase">{{$t('india')}}</label>
									<a href="https://goo.gl/maps/P9XwW8MjJTFLqRGv8" target="_blank"  class="text-white font-semibold text-[10px] lg:text-xs hover:underline transform hover:-translate-1 hover:scale-105 transition duration-100">
										<!-- 54-Rukmini Villa, 2nd Floor , Dambhare Layout, Near Trimurti nagar Ring Road, Nagpur 440022, INDIA -->
										{{$t('india-address')}}
									</a>
								</div>
							</div>
						</div>
						<div class="w-6/12">
							<div class="flex lg:gap-4">
								<div class="flex flex-col space-y-1 font-semibold">
									<label class="text-white uppercase">{{$t('japan')}}</label>
									<a href="https://goo.gl/maps/q7MNZPixuMG5KQE76" target="_blank" class="text-white font-semibold text-[10px] lg:text-xs hover:underline transform hover:-translate-1 hover:scale-105 transition duration-100 ">
										<!-- Jai-Bhim House, 1-4-34, Sakaechodiro, Tsurumi-Ku, Yokohama-Shi, Kanagawa-Ken 230-0038, Japan. -->
										{{$t('jai-bhim-house')}}
									</a>
								</div>
							</div>
						</div>
					</div>

					<div class="flex space-x-8 w-full gap-4 py-2">
						<div class="w-6/12">
							<div class="flex items-center lg:gap-2">
								<span class="w-[38px] lg:w-[45px]">
									<svg class="fill-current w-[15px] lg:w-[25px]" fill="none" viewBox="0 0 41 41"><path fill="#fff" fill-rule="evenodd" d="M36.4033 4.88242c.601 4.1558 1.1933 15.37948-7.6264 24.19918-8.8197 8.8197-20.04337 8.2274-24.19917 7.6264v-8.5039L13.31 26.7487l1.3183 2.6367c.3335.6669 1.0151 1.0882 1.7607 1.0882V28.505c0 1.9686.001 1.9686.002 1.9686h.0065l.0099-.0001.0243-.0003c.0181-.0003.0402-.0008.066-.0016.0516-.0017.1183-.0047.1992-.0101.1616-.0107.38-.031.6472-.0692.5341-.0763 1.2657-.2244 2.1281-.5119 1.7318-.5772 3.974-1.7114 6.183-3.9205 2.2091-2.209 3.3433-4.4512 3.9206-6.183.2874-.8624.4355-1.594.5118-2.1281.0382-.2672.0585-.4857.0693-.6473.0053-.0808.0084-.1475.01-.1991.0009-.0258.0014-.0479.0016-.066l.0003-.0243.0001-.0099v-.0065c0-.001 0-.002-1.9685-.002h1.9685c0-.6582-.3289-1.2728-.8766-1.6379l-4.5766-3.0511 2.8489-7.12228h8.8387ZM26.0868 17.6506l-3.555-2.37c-1.5492-1.0328-2.1631-3.0095-1.4716-4.7381l2.8489-7.12229C24.507 1.92546 25.9547.945312 27.5646.945312h8.9415c1.7885 0 3.4719 1.242478 3.7655 3.182958.668 4.41467 1.5772 17.44943-8.7107 27.73733C21.273 42.1534 8.23825 41.2443 3.82358 40.5763 1.8831 40.2827.640625 38.5993.640625 36.8108v-8.6067c0-1.9246 1.391425-3.5671 3.289845-3.8835l8.73223-1.4554c1.7049-.2842 3.3958.5769 4.1687 2.1228l.6821 1.3642c.2144-.0526.4537-.1202.7137-.2068 1.2211-.4071 2.916-1.2414 4.6441-2.9694 1.728-1.7281 2.5624-3.423 2.9694-4.6441.11-.3299.1892-.6266.2461-.8813Z" clip-rule="evenodd"/>
									</svg>
								</span>
								<div class="flex">
									<span class="text-white font-semibold text-[10px] lg:text-xs xl:text-sm ">
										Mob : +91 82-0885-7068
									</span>
								</div>
							</div>
						</div>
						<div class="w-6/12">
							<span class="text-white font-semibold text-[10px] lg:text-xs xl:text-sm ">
								Mob : +81 80-4186-1714
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="w-full h-[80.96px] bg-bgReg shadow-footerBottomShadow px-2 lg:px-9 md:flex items-center justify-center">
			<div class="md:w-[600px] lg:w-[826.83px] flex">
				<label class="w-[100px] lg:w-[130px]">
					<img src="/images/footer-recruit.webp" class="w-full">
				</label>
				<label class="w-[100px] lg:w-[130px]">
					<img src="/images/footer-trial.webp" class="w-full">
				</label>
				<label class="w-[100px] lg:w-[130px]">
					<img src="/images/footer-planner.webp" class="w-full">
				</label>
				<label class="w-[100px] lg:w-[130px]">
					<img src="/images/footer-jiipa.webp" class="w-full">
				</label>
			</div>

			<div class="md:w-[500px] lg:w-[473.17px] flex flex-col text-white text-xs lg:text-sm font-nunito pr-4 md:pr-10">
				<label class="text-right w-full"><a href="#" class="hover:underline">{{$t('made-by-sekai-ichi')}}</a></label>
				<label class="text-right w-full">© {{new Date().getFullYear()}} {{$t('sekai-engineering-it-solutions-private-limited  ')}}</label>
			</div>
		</div>
	</div>
</template>
<script>
    import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
    import { isEmpty } from 'lodash'
    export default  ({
        data: function() {
            return {
                intervalId: '',
                email: '',
				buyerBaseUrl: process.env.VUE_APP_SMART_ART_BUYER_URL,
				plannerUrl:process.env.VUE_APP_PLANNER_URL,
				recruitUrl:process.env.VUE_APP_RECRUIT_URL
            }
        },
        components: {

        },
        computed: {
            ...mapGetters({
                
            }),
        },
        methods: {     
            ...mapMutations({
                
            }),
            ...mapActions({
				
			}),

            async getUserAgreementSubscriptionData(){
                clearInterval(this.intervalId);
                if(!isEmpty(this.email)){
                    const payload = {
                        email: this.email
                    }
                    try{
                        const response = await this.$store.dispatch("auth/getUserAgreementSubscriptionData",{payload:payload});
                    } catch(error){
                        console.log("Error in response", error);
                    }finally{
                        this.gIndicator('stop');
                    }
                }
            },
        },
        mounted() {
        	window.scrollTo(0, 0);

            let udata = JSON.parse(localStorage.getItem("ls_data"));
            let userAuthToken = localStorage.getItem("localforage/smartartclient/authtoken");
            if(udata && userAuthToken !== null && userAuthToken != ""){
                this.email = udata.email;
            }

            this.gIndicator('start');
            this.intervalId = setInterval(() => {
                if(!isEmpty(this.email)){
                    this.getUserAgreementSubscriptionData();
                }
                //this.gIndicator('stop');
            }, 1000);
            
        },



    });
</script>

<style>
 
</style>