// finance and audit
// export const fetchFinanceAuditSettings = ({ commit, dispatch}) => {
//     return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/seller-setting').then((response) => {          
//         commit('setFinanceAuditSettingsData', response.data.data);  
//         return response.data.data;
//     }).catch((error) => {
//         dispatch('auth/errorHandler', error.response.status, { root: true });
//         commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
//         return error.response.data.success;
//     });
// }
// export const submitGenerals = ({ commit, dispatch }, { payload, context }) => {
//     return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/seller-setting', payload).then((response) => {
//         commit('SET_ALERT', 'success', { root: true })
//         commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
//         return response;
//     }).catch((error) => {
//         commit('SET_ALERT', 'warning', { root: true })
//         commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
//         context.errors = error.response.data.errors
//         return false;
//     })
// }

// Slack modules
export const fetchSlackModulesList = ({ commit, dispatch }, query='') => {
    let url = process.env.VUE_APP_SMART_ART_API_URL + 'api/slack-modules'+ query;
    return window.axios.get(url).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
};

export const fetchSlackWebhook = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/slack-webhook' + query)
    .then((response) => {
        commit('setSlackWebhook', response.data.data);
        return response.data.success;
    })
    .catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false; 
    });
};


export const storeUpdateSlackIntegration = ({ context, commit, dispatch }, { payload, id}) => {
    return window.axios.post (process.env.VUE_APP_SMART_ART_API_URL + 'api/slack-webhook/' + id, payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
        return response.data.success
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
};



export const fetchSlackWebhookById = ({ commit, dispatch }, id) => {
  return window.axios
    .get(process.env.VUE_APP_SMART_ART_API_URL + `api/slack-webhook/${id}`)
    .then((response) => {
      return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
};

export const deleteSlackWebhook = ({ commit, dispatch }, id) => {
    return window.axios
    .delete(process.env.VUE_APP_SMART_ART_API_URL + `api/slack-webhook/${id}`)
    .then((response) => {
        commit('SET_ALERT', 'success', { root: true });
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
        return response.data;
    })
    .catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
};
export const deleteModuleSlackInteg = ({ commit, dispatch }, id) => {
    return window.axios
    .delete(process.env.VUE_APP_SMART_ART_API_URL + `api/slack-webhook-module/${id}`)
    .then((response) => {
        commit('SET_ALERT', 'success', { root: true });
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
        return response.data;
    })
    .catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
};

export const updateSlackWebhookUrl = ({ context, commit, dispatch }, { payload, id}) => {
    return window.axios.post (process.env.VUE_APP_SMART_ART_API_URL + 'api/update-slack-webhook-url', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true });
        return response.data.success
    }).catch((error) => {
        return false
    })
};
export const storeGeneralSetting = ({ commit, dispatch }, {context, payload}) => {
    return window.axios.post (process.env.VUE_APP_SMART_ART_API_URL + 'api/store-client-settings', payload).then((response) => {
        return response.data.success;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        context.submitBtnIndicator = false;
        return false
    })
};
export const getGeneralSetting = ({ commit, dispatch },{context, payload}) => {
    return window.axios.get (process.env.VUE_APP_SMART_ART_API_URL + 'api/get-client-settings?user_id='+payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
};