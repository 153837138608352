import localforage from 'localforage'
import { isEmpty } from 'lodash'
import { setHttpToken, setHttpBaseUrl } from '../../../helpers'

export const store = ({ commit, dispatch},{payload}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/contact-us',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        //commit("SET_ALERT", "warning", { root: true });
        //commit("SET_ALERT_MESSAGE",  error.response.data.message, { root: true });
        return false;
    });   
};
export const verifyCaptcha = ({ commit, dispatch},{payload}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/recapcha-verification',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    });   
};