<template>
    <div>
        <div class="sidebar h-screen relative font-nunito" :class="[!collapse ? collapseClass : normalClass]">
            <transition name="slide">
                <section class="">
                    <div class="w-32 md:w-40 lg:w-44 xl:w-60 h-8 md:h-12 xl:h-[62px] flex justify-center items-center bg-white shadow-logoShadow rounded-br-[40px] flex items-center" v-if="collapse">
                        <img src="/images/logo.webp" class="w-20 md:w-28 xl:w-40">
                    </div> 

                    <div v-if="!collapse" class="flex justify-center py-6">
                        <img src="/images/user.webp" class="w-6 h-6 md:w-8 md:h-8 border border-white rounded-full">
                    </div>

                    <div class="flex items-center relative" :class="{ 'bg-white flex justify-center': !collapse, '': collapse }">
                        <div class="w-[90%] font-semibold flex justify-center items-center" v-if="collapse">
                            <label class="my-auto text-sm xl:text-base capitalize ">{{firstname}} {{lastname}}</label>
                        </div>
                        <div class="collapse-icon" v-on:click="collapseSidebar" :class="{ '': !collapse, 'w-[10%] flex justify-end  border border-sidePurple': collapse }">
                            <label class="cursor-pointer flex justify-center items-center " :class="{ 'rotate-180 rounded-r-md w-7 h-14 bg-white': collapse , 'py-1': !collapse }" >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6 text-sidePurple transform hover:-translate-1 hover:scale-125 transition duration-100" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 0 1 0-1.414L10.586 10 7.293 6.707a1 1 0 0 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0z" clip-rule="evenodd"/></svg>
                           </label>         
                       </div>      
                    </div>
                   
                    <div v-if="collapse" class="border-b-2 mx-6 my-2"></div>  

                    <div>
                        <div v-if="collapse" class="p-2">
                            <div class="relative w-full flex items-center">
                                <input type="text" class="text-white h-9 w-full m-2 bg-transparent ring-1 ring-white focus:outline-none focus:ring-1 focus:ring-blue-200 rounded-md px-3 text-sm" :placeholder="$t('search')" v-model="search"/>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6 absolute inset-y-0 right-4 flex items-center justify-center my-auto fill-current text-white" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM2 8a6 6 0 1 1 10.89 3.476l4.817 4.817a1 1 0 0 1-1.414 1.414l-4.816-4.816A6 6 0 0 1 2 8z" clip-rule="evenodd"/></svg>
                            </div>
                        </div>
                        <div  id="scrollbar" class="space-y-4 py-2 pl-2 bg-darkMenuPurple h-[24rem] 2xl:h-[40rem] overflow-y-auto " :class="{ 'mt-4': !collapse, '': collapse }">
                            <div v-for="(sideList,i) in sidebarList" :key="i">
                                <router-link :to="sideList.link" v-if="!sideList.isComingSoon" active-class="active" class="group relative overflow-hidden uppercase rounded-l-full font-semibold tracking-wider text-sm gap-6 h-10 cursor-pointer " 
                                    :class="{ 'flex items-center justify-center': !collapse, 'flex items-center px-5': collapse }">
                                        <div class="absolute inset-0 w-0 hover:bg-white hover:bg-opacity-50 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                                    <label v-html="sideList.svgIcon" class="bg-sidePurple rounded-full w-7 h-7 flex justify-center items-center"></label>   
                                    <label class="" v-if="collapse">{{sideList.menuName}}</label>
                                </router-link>
                                <a href="javascript:void(0)" title="coming soon..." v-if="sideList.isComingSoon" class="cursor-not-allowed group relative overflow-hidden uppercase rounded-l-full font-semibold tracking-wider text-sm gap-6 h-10" 
                                    :class="{ 'flex items-center justify-center': !collapse, 'flex items-center px-5': collapse }">
                                        <div class="absolute inset-0 w-0 transition-all duration-[250ms] ease-out group-hover:w-full"></div>
                                    <label v-html="sideList.svgIcon" class="bg-sidePurple rounded-full w-7 h-7 flex justify-center items-center"></label>   
                                    <label class="text-gray-400" v-if="collapse">{{sideList.menuName}}</label>
                                </a>
                            </div>
                       </div>
                    </div>

                    <div class="flex justify-center mt-4">
                        <button type="button" class="transform hover:-translate-1 hover:scale-110 transition duration-100" :class="{ '': !collapse, 'flex items-center gap-4 h-10 justify-center w-40 hover:bg-white hover:text-sidePurple rounded-lg': collapse }">
                            <span class="bg-[#400145] rounded-full w-8 h-8">
                                <svg class="flex mx-auto mt-1" xmlns="http://www.w3.org/2000/svg" width="23" height="22" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1.086 10.995 3.472 4.167m5.903-4.167H1.086h9.375Zm-9.375 0 3.472-4.167-3.472 4.167ZM9.418 5.79V3.888a2.083 2.083 0 0 1 2.256-2.076l8.334.694a2.084 2.084 0 0 1 1.91 2.076v12.833a2.084 2.084 0 0 1-1.91 2.076l-8.334.695a2.083 2.083 0 0 1-2.256-2.077v-1.902"/></svg>
                            </span>
                           <span v-if="collapse" @click="logout">Sign Out</span>
                        </button>
                    </div>
                </section>
           </transition>
       </div>
   </div>
</template>
<script>
    export default  ({
        data: function() {
            return {
                collapse: true,
                normalClass: 'w-full bg-sidePurple pb-4 text-white',
                collapseClass: 'w-20 bg-sidePurple pb-4 text-white',
                search: '',

                sideListname:[
                    {   
                        isComingSoon:false,
                        link:'/seller-dashboard', 
                        menuName:this.$t('seller-dashboard'),
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M2 22.82h20"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.75 4.82v18h4.5v-18c0-1.1-.45-2-1.8-2h-.9c-1.35 0-1.8.9-1.8 2ZM3 10.82v12h4v-12c0-1.1-.4-2-1.6-2h-.8c-1.2 0-1.6.9-1.6 2ZM17 15.82v7h4v-7c0-1.1-.4-2-1.6-2h-.8c-1.2 0-1.6.9-1.6 2Z"/></svg>`, 
                    },
                    {   
                        isComingSoon:false,
                        link:'/seller-profile', 
                        menuName:this.$t('seller-profile'),
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" fill="none"><path fill="#fff" d="M8 2.249c.792 0 1.565.21 2.223.602.657.392 1.17.95 1.473 1.603.303.652.382 1.37.228 2.063a3.471 3.471 0 0 1-1.095 1.829c-.56.5-1.272.84-2.048.977A4.448 4.448 0 0 1 6.47 9.12a3.937 3.937 0 0 1-1.795-1.316A3.306 3.306 0 0 1 4 5.82c0-.947.422-1.855 1.172-2.525.75-.67 1.768-1.046 2.828-1.046ZM8 .82c-1.107 0-2.19.294-3.11.843-.922.55-1.64 1.33-2.063 2.244a4.505 4.505 0 0 0-.319 2.889c.216.97.75 1.86 1.533 2.56a5.836 5.836 0 0 0 2.867 1.368 6.226 6.226 0 0 0 3.235-.284c1.024-.379 1.898-1.02 2.514-1.842A4.628 4.628 0 0 0 13.6 5.82c0-1.326-.59-2.598-1.64-3.535C10.91 1.347 9.486.82 8 .82ZM16 20.821h-1.6V17.25c0-.947-.421-1.856-1.172-2.525-.75-.67-1.767-1.046-2.828-1.046H5.6c-1.06 0-2.078.376-2.828 1.046S1.6 16.303 1.6 17.25v3.571H0V17.25c0-1.326.59-2.598 1.64-3.536 1.05-.937 2.475-1.464 3.96-1.464h4.8c1.485 0 2.91.527 3.96 1.464 1.05.938 1.64 2.21 1.64 3.536v3.571ZM16 2.25h8v1.429h-8V2.25ZM16 5.82h8V7.25h-8V5.82ZM16 9.39h5.6v1.43H16V9.39Z"/></svg>`,
                    },

                    {   
                        isComingSoon:false,
                        link:'/buy-orders', 
                        menuName:this.$t('orders '),
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="23" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 1.32H2a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-18a1 1 0 0 0-1-1Z"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 1.32h4.5v8l-2.25-2-2.25 2v-8Z"/><path stroke="#fff" stroke-linecap="round" stroke-width="1.5" d="M5 13.32h5M5 16.32h8"/></svg>`,
                    },
                    
                    {   
                        isComingSoon:true,
                        link:'/my-tasks/orders/buy', 
                        menuName:this.$t('my-tasks'),
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" fill="none"><path fill="#fff" d="M2.222 22.543h15.556A2.224 2.224 0 0 0 20 20.32V3.654a2.224 2.224 0 0 0-2.222-2.223h-2.222a1.111 1.111 0 0 0-1.112-1.11H5.556a1.111 1.111 0 0 0-1.112 1.11H2.222A2.224 2.224 0 0 0 0 3.654V20.32c0 1.226.997 2.223 2.222 2.223Zm0-18.89h2.222v2.223h11.112V3.654h2.222V20.32H2.222V3.654Z"/><path fill="#fff" d="m8.89 13.191-1.993-1.992-1.57 1.57 3.563 3.564 5.785-5.785-1.571-1.571L8.89 13.19Z"/></svg>`,
                    },
                    
                    {   
                        isComingSoon:false,
                        link:'/art-library', 
                        menuName:this.$t('library'),
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><g fill="#fff" clip-path="url(#a)"><path d="M2.72 18.01h2.8a.96.96 0 0 0 .95-.951V5.178l-2.35-2.35-2.346 2.346v11.88a.96.96 0 0 0 .95.951l-.004.004Zm.255-12.34L4.12 4.524 5.266 5.67l.004 8.016h-2.3l.005-8.016Zm-.005 9.212h2.3v1.927h-2.3v-1.927Z"/><mask id="b"><path d="M7.803 3.313h15v15h-15v-15Zm4.166 5.833a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667Zm-3.333 8.333 4.167-5 2.5 2.5 3.333-4.166 4.167 5"/></mask><path d="M7.803 3.313v-1h-1v1h1Zm15 0h1v-1h-1v1Zm0 15v1h1v-1h-1Zm-15 0h-1v1h1v-1Zm5-5.834.707-.707-.774-.774-.702.841.769.64Zm2.5 2.5-.707.707.79.79.698-.872-.781-.625Zm3.333-4.166.768-.64-.784-.942-.765.957.781.624Zm-10.833-6.5h15v-2h-15v2Zm14-1v15h2v-15h-2Zm1 14h-15v2h15v-2Zm-14 1v-15h-2v15h2Zm3.166-8.167c.487 0 .953-.193 1.297-.537L11.85 8.195a.167.167 0 0 1 .118-.05v2Zm1.297-.537c.344-.344.537-.81.537-1.296h-2c0-.045.017-.087.048-.118l1.415 1.414Zm.537-1.296c0-.487-.193-.953-.537-1.297L11.85 8.43a.167.167 0 0 1-.048-.117h2Zm-.537-1.297a1.834 1.834 0 0 0-1.297-.537v2a.167.167 0 0 1-.117-.049l1.414-1.414Zm-1.297-.537c-.486 0-.952.193-1.296.537l1.414 1.414a.167.167 0 0 1-.118.05v-2Zm-1.296.537c-.344.344-.537.81-.537 1.296h2a.167.167 0 0 1-.049.118l-1.414-1.414Zm-.537 1.296c0 .487.193.953.537 1.297l1.414-1.414a.167.167 0 0 1 .05.117h-2Zm.537 1.297c.344.344.81.537 1.296.537v-2c.045 0 .087.017.118.049l-1.414 1.414Zm-1.269 8.51 4.167-5-1.537-1.28-4.166 5 1.536 1.28Zm2.692-4.933 2.5 2.5 1.414-1.414-2.5-2.5-1.414 1.414Zm3.988 2.418 3.333-4.167-1.562-1.25-3.333 4.168 1.562 1.249Zm1.784-4.151 4.167 5 1.536-1.28-4.167-5-1.536 1.28Z" mask="url(#b)"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 .32h24v24H0z"/></clipPath></defs></svg>`,
                    },
                    /* {   
                        link:'/photo-library', 
                        menuName:'Photo Library',
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" fill="none"><path stroke="#fff" stroke-width="1.2" d="m12.639 9.999 3.857 1.821 3-3.75 3.75 4.5m-13.5-8.357V1.32h13.5v13.5H12.64m.857-8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"/><path fill="#fff" d="M1.35 16.328a1.3 1.3 0 0 1-.953-.396A1.3 1.3 0 0 1 0 14.978v-8.1c0-.371.132-.689.397-.953.264-.265.582-.397.953-.397h2.126l1.249-1.35h4.05v1.35h-3.46l-1.231 1.35H1.35v8.1h10.8V7.553h1.35v7.425c0 .371-.132.69-.396.954a1.302 1.302 0 0 1-.954.396H1.35Zm11.239-9.45c0-.877-.304-1.62-.912-2.227-.607-.608-1.35-.912-2.227-.912v-.91c1.125 0 2.081.393 2.869 1.18.787.788 1.181 1.744 1.181 2.87h-.911Zm-1.789 0a1.3 1.3 0 0 0-.396-.953 1.3 1.3 0 0 0-.954-.397v-.911c.619 0 1.145.22 1.578.658.433.439.66.973.683 1.603H10.8Zm-4.05 7.088a2.93 2.93 0 0 0 2.152-.886 2.93 2.93 0 0 0 .886-2.152c0-.844-.296-1.56-.886-2.152a2.93 2.93 0 0 0-2.152-.885 2.93 2.93 0 0 0-2.152.885 2.931 2.931 0 0 0-.885 2.152c0 .844.295 1.561.885 2.152a2.93 2.93 0 0 0 2.152.886Zm0-1.35a1.63 1.63 0 0 1-1.198-.49 1.63 1.63 0 0 1-.49-1.198c0-.472.164-.872.49-1.198a1.63 1.63 0 0 1 1.198-.49c.473 0 .872.164 1.198.49.326.326.49.726.49 1.198 0 .473-.164.872-.49 1.198a1.63 1.63 0 0 1-1.198.49Z"/></svg>`,
                    },
                    {   
                        link:'/frame-library', 
                        menuName:'Frame Library',
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" fill="none"><path stroke="#fff" d="m3.725 16.004 3.625-4.35 2.175 2.175 2.9-3.625 3.624 4.35M3 3.68h13.05v13.05H3V3.68Zm3.625 5.074a.725.725 0 1 0 0-1.45.725.725 0 0 0 0 1.45Z"/><path stroke="#fff" stroke-width="1.5" d="M1 1.68h17.049v17.049H1z"/></svg>`,
                    },
                    {   
                        link:'/design-library', 
                        menuName:'Design Library',
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" fill="none"><path stroke="#fff" d="m12.562 9.784 2.954 1.555 2.954-3.693 3.692 4.431M8.869 4.91V1h13.293v13.293H8.869m3.693-8.124a.738.738 0 1 0 0-1.476.738.738 0 0 0 0 1.476Z"/><path fill="#fff" d="M12.308 4.695H1.231C.554 4.695 0 5.25 0 5.926v7.385a1.231 1.231 0 0 0 1.23 1.23H5.54v1.232H4.308v1.23H9.23v-1.23h-1.23v-1.231h4.307c.683 0 1.231-.548 1.231-1.231V5.926a1.23 1.23 0 0 0-1.23-1.23Zm0 8.616H1.231V5.926h11.077v7.385Zm-3.175-4.8-.622.621-1.274-1.249.622-.628c.123-.129.332-.135.48 0l.794.77c.129.129.135.338 0 .486Zm-4.825 2.283 2.566-2.579 1.274 1.28-2.56 2.585h-1.28v-1.286Z"/></svg>`,
                    }, */
                    {   
                        isComingSoon:true,
                        link:'/exhibitions/all', 
                        menuName:this.$t('exhibitions'),
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" fill="none"><path stroke="#fff" stroke-width=".7" d="M.539 10.589V1h6.752M.539 10.589l6.752-1.646M.539 10.59v5.39H0h.51m.029-5.39-.028 5.39m6.78-7.036V1m0 7.943h9.503m-9.503 0v7.036h.596-.639m.043-7.036-.043 7.036M7.291 1h9.503m0 0v7.943m0-7.943h6.724v9.418m-6.724-1.475 6.724 1.475m-6.724-1.475v7.036h.596-.624m.028-7.036-.028 7.036m6.752-5.56v5.56h-.454.468m-.014-5.56.014 5.56m-22.51 0H.51m23.489 0h-.468m-7.39 0h.624m-10.156 0h.638m1.518-8.71V2.42h6.496v4.85m-6.496 0h6.496m-6.496 0 1.957-2.354 1.107 1.56 1.447-2.44 1.985 3.234M18.1 2.42h3.972v6.155l-3.972-1.02V2.417Zm-16.198 0h4.028v5.333l-4.028.993V2.418Z"/></svg>`,
                    },
                    {   
                        isComingSoon:false,
                        link:'/refferals-list', 
                        menuName:this.$t('referrals'),
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none"><path fill="#fff" d="M9 12.5H7a9 9 0 0 1 9-9v2c-3.87 0-7 3.13-7 7Zm7-3v-2c-2.76 0-5 2.24-5 5h2c0-1.66 1.34-3 3-3Zm-11-7c0-1.11-.89-2-2-2-1.11 0-2 .89-2 2 0 1.11.89 2 2 2 1.11 0 2-.89 2-2Zm4.45.5h-2A2.99 2.99 0 0 1 4.5 5.5h-3C.67 5.5 0 6.17 0 7v2.5h6V7.24A4.97 4.97 0 0 0 9.45 3ZM17 15.5c1.11 0 2-.89 2-2 0-1.11-.89-2-2-2-1.11 0-2 .89-2 2 0 1.11.89 2 2 2Zm1.5 1h-3a2.99 2.99 0 0 1-2.95-2.5h-2A4.97 4.97 0 0 0 14 18.24v2.26h6V18c0-.83-.67-1.5-1.5-1.5Z"/></svg>`,
                    },
                    {   
                        isComingSoon:false,
                        link:'/crowdfunding-list', 
                        menuName:this.$t('crowd-funding '),
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" fill="none"><path fill="#fff" d="M19.089 16.435s-5.748 2.032-6.599 2.33c-.963.334-1.953.587-2.958.757a23.343 23.343 0 0 1-1.499-.188 5.238 5.238 0 0 0-2.11.212 3.753 3.753 0 0 1-.483.357c-.407.139-.848.139-1.255 0a9.874 9.874 0 0 1-2.17-1.287 6.668 6.668 0 0 1-1.592-1.962 2.534 2.534 0 0 1-.283-2.232c.184-.186.356-.383.518-.588a2.39 2.39 0 0 0 .553-.785c.271-.537.494-1.096.667-1.671a7.82 7.82 0 0 1 1.452-2.22s-1.217 4.609-1.26 4.79c-.043.18.138.168.785-.063a1.134 1.134 0 0 0 .785-.699s.568-1.851.639-2.032c.07-.18.204.043.204.043s1.299 6.309 1.318 6.552a.624.624 0 0 0 .824.502.734.734 0 0 0 .44-.714l-.785-3.888c0-.063.035-.098.094-.121.059-.024.141.047.141.047s1.012 5.1 1.055 5.163a.526.526 0 0 0 .691.321.706.706 0 0 0 .506-.675l-.969-4.927a.11.11 0 0 1 .102-.09c.075 0 .118.094.118.094s.784 3.923.804 4.029a.545.545 0 0 0 .722.392.745.745 0 0 0 .525-.784c0-.024-.87-4.355-.874-4.382a.145.145 0 0 1 .106-.134c.074 0 .14.18.14.18s.483 2.425.55 2.747a.57.57 0 0 0 .738.51.745.745 0 0 0 .541-.612L9.893 8.353a2.236 2.236 0 0 0-.42-.804 14.654 14.654 0 0 1-1-1.464A2.24 2.24 0 0 1 8.08 4.38s3.598-3.57 3.951-3.95l.055-.036.035-.04a1.201 1.201 0 0 1 1.703 1.696c-.13.133-4.068 4.068-4.096 4.123a.302.302 0 0 0 .043.235.784.784 0 0 0 .208.102S15.762.81 16.088.468a.132.132 0 0 1 .035-.032.268.268 0 0 1 .059-.043 1.178 1.178 0 0 1 1.687 1.644c-.357.357-5.363 5.343-5.493 5.465-.13.121.024.266.079.345.055.078.29-.031.29-.031s6.261-6.246 6.736-6.701h.02l.066-.055.051-.051a1.177 1.177 0 0 1 1.57.106 1.177 1.177 0 0 1 0 1.683l-.051.043s-6.842 6.771-6.913 6.862c-.07.09 0 .215.082.31.083.094.283 0 .283 0s4.543-4.496 5.296-5.258a1.213 1.213 0 0 1 .985-.349c.321.11.597.322.784.605a1.063 1.063 0 0 1-.176 1.392.43.43 0 0 1-.063.06l-8.858 8.96c-.393.415.286.392.286.392s2.7-.785 3.727-1.056a2.394 2.394 0 0 1 1.895.17c.396.29.727.662.969 1.09.184.318-.345.416-.345.416Z"/></svg>`,
                    },
                    {   
                        isComingSoon:false,
                        link:'/settings/general', 
                        menuName:this.$t('settings'),
                        svgIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M22 7h-6M6 7H2M10 10.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM22 18h-4M8 18H2M14 21.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"/></svg>`,
                    },
                ],

                firstname: '',
                lastname: '',
            }
        },

        computed: {
            sidebarList() {
               let self = this;
               return this.sideListname.filter((cust) => {
                   return cust.menuName.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
               });
           }
       },
        components: {
        },

        methods: {
            collapseSidebar() {
                this.collapse = !this.collapse;
                this.$root.collapseSidebar();  
            },

            async getProfileDetails(){
                let emailid = localStorage.getItem('email');
                const payload = {
                    email : emailid
                }
                try{
                    const res = await this.$store.dispatch("myProfile/getProfileDetails",{payload: payload});
                    this.firstname = res.firstname;
                    this.lastname = res.lastname;
                    
                } catch(error){
                    console.log("Error in response", error);
                }finally{
                    // this.gIndicator('stop');
                }
            },

            logout(){
                const email = localStorage.getItem('email');
                const password = localStorage.getItem('password');
                const keepMeLogin = localStorage.getItem('keepMeLoggedIn');
                const selectedLanguage = localStorage.getItem('selectedLanguage');
                const local = localStorage.getItem('localforage/smartartclient/locale');
                localStorage.clear();
                if(keepMeLogin){
                    localStorage.setItem('email', email);
                    localStorage.setItem('password', password);
                    localStorage.setItem('keepMeLoggedIn', keepMeLogin);
                }
                localStorage.setItem('selectedLanguage', selectedLanguage);
                localStorage.setItem('localforage/smartartclient/locale', local);
                window.location.href = process.env.VUE_APP_SMART_ART_SELLER_URL+'/sign-in';
            },
        },


        mounted() {
           this.getProfileDetails();
        },
    });
</script>

<style>
    .collapse-icon {
      transition: 0.1s linear;
  }
  .rotate-180 {
      transform: rotate(180deg);
      transition: 0.2s linear;
  }
  .sidebar{
    transition: 0.1s ease;      
}
/*~~~~~~~~~~~~~~scrollbar classes*/
/*#scrollbar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 8px;
    background-color: #F5F5F5;
}

#scrollbar::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}

#scrollbar:hover::-webkit-scrollbar-thumb
{
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #717171;
}*/

/*~~~~~~~~~~~~~~~~~~~~*/
.active {
    background-color: #fff;
    color: #6B42AD;
}
/*.menu_active.router-link-exact-active{
  background-color: #fff;
  color: #6B42AD;
  text-decoration: none;  
}*/
</style>