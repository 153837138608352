export default [
	{
		path: '/sign-in',
		component: () => import('../components/SignIn.vue'),
		name: 'sign-in',

	},
	{
		path: '/sign-up',
		component: () => import('../components/SignUpParent.vue'),
		children:[
			{
				path: '/sign-up',
				component: () => import('../components/SignUp.vue'),
				name: 'sign-up',

			},
			{
				path: '/sign-up/services',
				component: () => import('../components/partials/Services.vue'),
				name: 'optional-services',

			},
			{
				path: '/sign-up/payment',
				component: () => import('../components/partials/Payment.vue'),
				name: 'sign-up-payment',
			},
			{
				path: '/payment',
				component: () => import('../components/partials/Payment.vue'),
				name: 'payment',
			},

            {
                path: "/success",
                name: "stripPaymentSuccess",
                component: () => import('../components/partials/stripPaymentSuccess.vue'),
                
            },

			{
				path: '/reset-password',
				component: () => import('../components/partials/ResetPassword.vue'),
				name: 'reset-password',
			},
			{
                path: "/paypal-error",
                name: "PaypalError",
                component: () => import('../components/partials/PaypalError.vue'),
                
            },
			{
                path: "/paypal-success",
                name: "PaypalSuccess",
                component: () => import('../components/partials/PaypalSuccess.vue'),
                
            },
		]
		
	},
]