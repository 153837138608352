<template>
	<div>
		<div class="flex items-center pb-1 font-nunito">
           <div class="block lg:hidden w-40 h-[60px] h-12 flex justify-center items-center bg-white shadow-logoShadow rounded-br-[40px] flex items-center">
                <img src="/images/logo.webp" class="w-24 object-contain">
            </div>             
            <div class="shadow-logoShadow rounded-bl-[40px] w-full ml-3 xl:ml-6 h-[50px] lg:h-[65px] flex justify-end">
                <div class="flex px-2 lg:px-4 2xl:px-8 items-center gap-2 md:gap-1 xl:gap-6 uppercase font-semibold text-[10px] lg:text-xs xl:text-sm 2xl:text-base tracking-wider w-full flex justify-end">
                    <a href="javascript:void(0)" @click="redirectBtn('/')" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 py-1.5 rounded">
                    <!-- Home -->{{$t('home')}}
                    </a>
                    <a href="javascript:void(0)" @click="redirectBtn('/gallery/art-gallery')" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 py-1.5 rounded" ><!-- Art Gallery -->{{$t('art-gallery')}}</a>
                    <a href="javascript:void(0)" @click="redirectBtn('/gallery/photo-gallery')" class="hidden md:block hover:bg-opacity-50 hover:bg-bgBlue px-1 py-1.5 rounded" ><!-- Photo Gallery -->{{$t('photo-gallery')}}</a>
                    <a href="javascript:void(0)" title="coming soon..." class="cursor-not-allowed hidden md:block text-gray-400 px-1 py-1.5 rounded" >
                    <!-- Exhibit -->{{$t('exhibit')}}
                    </a>
                    <a href="javascript:void(0)" title="coming soon..." class="cursor-not-allowed hidden md:block text-gray-400 px-1 py-1.5 rounded" >
                    <!-- Art/Photo Exhibition -->{{$t('art-photo-exhibition')}}
                    </a>
                    <div class="relative">
                        <button type="button" class="flex items-center rounded-full" @click="dropUser = !dropUser">
                            <img :src="profile_image" class="w-4 md:w-6 h-4 md:h-6 xl:w-8 xl:h-8 border border-gray-400 hover:border-bgBlue rounded-full object-cover">
                        </button>
                        <button v-if="dropUser" @click="dropUser = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                        <div v-if="dropUser" class="relative">
                            <div class="absolute top-4 -right-4 bg-white border backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-44 z-30">
                                <router-link :to="{name:'seller-dashboard'}" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex" >
                                    <!-- Seller Dashboad  -->{{$t('seller-dashboard')}}
                                </router-link>
                                <router-link :to="{name:'seller-profile'}" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex" >
                                    <!-- Seller Profile -->{{$t('seller-profile')}}
                                </router-link>
                                <a href="javascript:void(0)" @click="logout" class="hover:bg-bgBlue px-2.5 py-2.5 text-bgBlue hover:text-white rounded-md w-full flex" >
                                    <!-- Sign Out -->{{$t('sign-out')}}
                                </a>
                            </div>
                        </div>
                    </div> 
                    
                    <div class="w-6 md:w-10 relative">
                        <button type="button" @click="dropNotification = !dropNotification" class="relative flex justify-center">
                            <svg class="fill-current text-black w-4 md:w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"><path d="M109.5 883V762h90V518.5q0-94 54.5-170t145-98V229q0-33.583 23.708-57.042Q446.417 148.5 480 148.5t57.292 23.458Q561 195.417 561 229v21.5q91.5 22 145.5 97.5t54 170.5V762h91v121h-742ZM481 1003q-37.45 0-63.725-25.981Q391 951.037 391 914h179q0 37.5-25.981 63.25Q518.037 1003 481 1003Z"/></svg>

                            <label class="absolute -top-2 -right-2 text-[8px] md:text-[10px] bg-red-500 text-white rounded-full h-4 md:h-5 w-4 md:w-5 flex justify-center items-center" v-if="notificationCount && notificationCount > 0">{{notificationCount ? notificationCount : ''}}</label>
                        </button>
                        <button v-if="dropNotification" @click="dropNotification = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                        <div v-if="dropNotification" class="absolute top-12 -right-24 md:-right-4 bg-white border backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-64 h-96 capitalize z-30 overflow-auto">
                            <div v-if="notifications.length" class="divide-y divide-gray-400">
                                <div v-for="(item,i) in notifications" :key="i" class="h-full">
                                    <button type="button" class="hover:bg-gray-200 px-2.5 py-2.5 text-gray-700 hover:text-gray-800 rounded-md w-full flex cursor-pointer" @click="markAsSeen(item)">
                                        <label class="font-semibold text-left">{{item.type+' : '}}
                                            <span class="font-normal">{{item.message}}</span>
                                        </label>
                                    </button>
                                </div>
                            </div>
                            <div v-else class="flex justify-center items-center h-full">
                                <label class="px-2.5 py-2.5 flex justify-center items-center h-full">
                                <!-- You don't have any notification -->{{$t('you-do-not-have-notifications')}}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="relative flex justify-center items-center rounded h-9 px-1.5 border hover:border-gray-400 bg-white">
                        <button type="button" class="flex items-center gap-2" @click="dropLang = !dropLang">
                            <svg class="w-2.5 lg:w-4 pointer-events-none" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
                            </svg>
                            <span>{{ languageName }}</span>
                            <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropLang }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="#000"/></svg>
                        </button>
                        <div v-if="dropLang" class="w-32 absolute right-0 top-9 bg-white backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs z-40 border">
                            <ul class="">
                                <li
                                    v-for="(language, index) in languages" :key="index"
                                    @click="selectLang(language.slug,language.name); dropLang = false"
                                    class="hover:bg-bgBlue py-1.5 px-2 text-bgBlue hover:text-white rounded-md cursor-pointer">
                                    {{ language.name }}
                                </li>
                            </ul>
                        </div>
                        <button v-if="dropLang" @click="dropLang = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                    </div> 

                </div>

                <div class="block md:hidden flex items-center mr-2">
                    <button type="button" @click="mobileHeader = !mobileHeader">
                        <svg class="text-black w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- mobile header -->
            <div class="md:hidden" v-if="mobileHeader">
                <div class="opacity-25 fixed inset-0 z-50 bg-black"></div>
                <div>   
                    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 right-0 z-50 outline-none focus:outline-none ">
                        <div class="relative w-full mx-4 md:mx-0 px-4 md:px-0">       
                            <div class="shadow-lg rounded-l-md h-screen relative flex  bg-white outline-none focus:outline-none mx-auto border-l-2 border-y-2 border-blue-200 relative">        
                                <div class="flex flex-col px-4 py-2 space-y-3">
                                    <a href="/" class="hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Home -->{{$t('home')}}</a>
                                    <a href="javascript:void(0)" @click="redirectBtn('/art-gallery')" class="hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Art Gallery -->{{$t('art-gallery')}}</a>
                                    <a href="javascript:void(0)" @click="redirectBtn('/photo-gallery ')" class="hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" ><!-- Photo Gallery -->{{$t('photo-gallery')}}</a>
                                    <a href="javascript:void(0)" title="coming soon..." class="cursor-not-allowed text-gray-400 px-1 lg:px-2 py-1.5 rounded" ><!-- Exhibit -->{{$t('exhibit')}}</a>
                                    <a href="javascript:void(0)" title="coming soon..." class="cursor-not-allowed text-gray-400 px-1 lg:px-2 py-1.5 rounded" ><!-- Art/Photo Exhibition -->{{$t('art-photo-exhibition')}}</a>
                                </div>

                                <div class="absolute inset-y-2 right-2">
                                    <button type="button" @click="mobileHeader = !mobileHeader">
                                        <svg class="ill-current text-black w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <div v-if="isConfirm">
        <Confirmation 
            :title="'confirmation-to-redirect'"
			:message="'you-are-being-redirected-to-buyer-side-want-to-continue'"
			@closeModelBox = "closeModelBox"
			@deleteRecord = "redirectToBuyer" 
        />
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import Confirmation from "@/components/DeleteRecordModelBox.vue";
import localforage from 'localforage';
import { isEmpty } from 'lodash';
export default  ({
    data: function() {
        return {
            intervalId: '',
            locale: localStorage.getItem("localforage/smartartclient/locale") ? localStorage.getItem("localforage/smartartclient/locale").replace(/['"]+/g, '') : 'en',
            languageName: (localStorage.getItem('selectedLanguage') == '' || localStorage.getItem('selectedLanguage') === 'null') ? 'English' : localStorage.getItem('selectedLanguage'),
            languages: [],
            dropLang: false,
            dropUser: false,
            mobileHeader: false,   
            profile_image: '/images/user.webp',     
            url:'',
            isConfirm:false,
            buyerBaseUrl: process.env.VUE_APP_SMART_ART_BUYER_URL,
            notifications:[],
            notificationCount:0,
            dropNotification:false,

        }
    },
    components: {
        Confirmation
    },
    computed: {
        ...mapGetters({
        }),
           
    },
    methods: {
        ...mapMutations({
        }),

        async getLanguages(){
            try{
                const res = await this.$store.dispatch("getLanguages",{});
                if(res.success){
                    this.languages = res.data;
                }
            }catch(e){
                console.log(e);
            }
        },

        selectLang(lang_slug,lang_name) {
            this.locale = lang_slug;
            this.languageName = lang_name;
            localStorage.setItem('selectedLanguage', lang_name);
            localforage.setItem('locale', this.locale);
            window.location = window.location.href
        },

        async getProfileDetails(){
            let emailid = localStorage.getItem('email');
            const payload = {
                email : emailid
            }
            try{
                const res = await this.$store.dispatch("myProfile/getProfileDetails",{payload: payload});
                if(res.profile_image){
                        this.profile_image = res.profile_image;
                    }
                
            } catch(error){
                console.log("Error in response", error);
            }finally{
               
            }
        },
        closeModelBox(){
            this.isConfirm = false;
            this.url = '';
        },
        redirectBtn(query){
            this.isConfirm = true;
            this.url = query;
        },
        redirectToBuyer(){
            window.open(this.buyerBaseUrl+this.url);
            this.closeModelBox();
        },
        async getNotifications(){
            try{
                const res = await this.$store.dispatch("getBellNotification");
                if(res.success){
                    this.notifications = res.data.data;
                    this.notificationCount = res.count;
                }
                
            } catch(error){
                console.log("Error in response", error);
            }
        },
        async markAsSeen(data){
            try{
                const res = await this.$store.dispatch("markSeenBellNotification",{payload:{id:data.id}});
                if(res.success){
                    window.open(process.env.VUE_APP_LOCAL_API+data.link,'frameName');
                }
                
            } catch(error){
                console.log("Error in response", error);
            }
        },

        logout(){
            const email = localStorage.getItem('email');
            const password = localStorage.getItem('password');
            const keepMeLogin = localStorage.getItem('keepMeLoggedIn');
            const selectedLanguage = localStorage.getItem('selectedLanguage');
            const local = localStorage.getItem('localforage/smartartclient/locale');
            localStorage.clear();
            if(keepMeLogin){
                localStorage.setItem('email', email);
                localStorage.setItem('password', password);
                localStorage.setItem('keepMeLoggedIn', keepMeLogin);
            }
            localStorage.setItem('selectedLanguage', selectedLanguage);
            localStorage.setItem('localforage/smartartclient/locale', local);
            window.location.href = process.env.VUE_APP_SMART_ART_SELLER_URL+'/sign-in';
        },

        async loadfunction(){
            clearInterval(this.intervalId);
            await this.getLanguages();
            this.getNotifications();
            this.getProfileDetails();
        }
    },

    mounted() {
        this.intervalId = setInterval(() => {
            this.loadfunction();
        }, 1000);
    },
    
});
</script>