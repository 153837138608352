import localforage from 'localforage'
import { isEmpty } from 'lodash'
import { setHttpToken, setHttpBaseUrl } from '../../../helpers'

// -----------------------------------------------------------------------------------------------------------------------------------------------

export const testConnection = ({ commit, dispatch}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/client-test-connection').then((response) => {
        if(response.data.success){
            return true;
        }
        return false;
    }).catch((error) => {
        dispatch('errorHandler',error.response.status);
        return false;
    });   
};

// -----------------------------------------------------------------------------------------------------------------------------------------------

export const sendotp = ({ commit, dispatch }, {payload, context}) => {
    let email = '';
    let otp = '';
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/get-otp',payload).then((response) => {
        if(response.data.success){
            email = response.data.email;
            otp = response.data.otp;
            payload.email = email;
            payload.otp = otp;
            return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/send-otp', payload).then((res) => {
                if(res.data.success == true){
                    commit('SET_ALERT', 'success', { root: true })
                    commit('SET_ALERT_MESSAGE', res.data.message, { root: true })
                    return res;
                } else {
                    commit('SET_ALERT', 'warning', { root: true })
                    commit('SET_ALERT_MESSAGE', res.data.message, { root: true })
                    return res;
                }
            }).catch((error) => {
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                context.errors = error.response.data.errors
                return false;
            })
        }
    }).catch ((error) => {
        dispatch('errorHandler',error.response.status);
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong sendotp " + error, { root: true });
    });
}

export const verifyEmail = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/verify-email',payload).then((response) => {
        if(response.data.success){
            if (response.data && response.data.success) {
                return response.data.success
            } else if (response.data && response.data.error) {
                console.log("email verify error response on UI");
            }
            return response.data;
        }
    }).catch ((error) => {
        dispatch('errorHandler',error.response.status);
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong verifyEmail " + error, { root: true });
    });    
}

export const verifiedEmail = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/verified-email',payload).then((response) => {
        if(response.data.success){
            if (response.data && response.data.success) {
                return response.data.success
            } else if (response.data && response.data.error) {
                console.log("email verify error response on UI");
            }
            return response.data;
        }
    }).catch ((error) => {
        dispatch('errorHandler',error.response.status);
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong verifiedEmail " + error, { root: true });
    });
}

// -----------------------------------------------------------------------------------------------------------------------------------------------

export const registerSSO = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/signup', payload).then((res) => {
        if(res.data.success){
            localStorage.setItem("sso_data", JSON.stringify(res.data.user));
            localStorage.setItem("localforage/smartartclient/authtoken", res.data.token);
            return res;
        } else {
            dispatch('errorHandler',error.response.status);
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong in registerSSO', { root: true })
            return false;
        }
    }).catch((error) => {
        if(error.response.status == 422){
            let laraErrors = error.response.data.errors; // this should be errors.
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', laraErrors.email, { root: true })
        } else if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else {
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            context.errors = error.response.data.errors
            return false;
        }
    })
}

export const registerLocal = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/sign-up', payload).then((response) => {
        if(response.data.success == true){
            commit('SET_ALERT', 'success', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            return response;
        } else {
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            return response;
        }
    }).catch((error) => {
        dispatch('errorHandler',error.response.status);
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const signup_services = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/sign-up-services', payload).then((res) => {
        localStorage.setItem("ls_data", JSON.stringify(res.data.user));
        return res;
    }).catch((error) => {
        if(error.response.status == 422){
            let laraErrors = error.response.data.errors; // this should be errors.
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', laraErrors.email, { root: true })
        } else if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else {
            dispatch('errorHandler',error.response.status);
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            context.errors = error.response.data.errors
            return false;
        }
    })
}

export const signup_payment = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/sign-up-payment', payload).then((res) => {
        localStorage.setItem("ls_data", JSON.stringify(res.data.user));
        return res;
    }).catch((error) => {
        if(error.response.status == 422){
            let laraErrors = error.response.data.errors; // this should be errors.
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', laraErrors.email, { root: true })
        } else if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else {
            dispatch('errorHandler',error.response.status);
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            context.errors = error.response.data.errors
            return false;
        }
    })
}

export const retriveStripData = ({ commit, dispatch }, {payload}) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL+'api/retrive-stripe-data?session_id='+payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch('errorHandler',error.response.status);
       commit("SET_ALERT", "warning", { root: true });
       commit("SET_ALERT_MESSAGE", "Something went wrong " + error, { root: true });
       return false;
    });
};

export const stripeSuccess = ({ commit, dispatch }, {payload}) => {
   return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL+'api/stripe-payment-success',payload).then((response) => {
       return response.data;
    }).catch((error) => {
        dispatch('errorHandler',error.response.status);
       commit("SET_ALERT", "warning", { root: true });
       commit("SET_ALERT_MESSAGE", "Something went wrong " + error, { root: true });
       return false;
    });       
};

export const externalSignIn = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post (process.env.VUE_APP_SSO_API_URL + 'api/signin/external-signin', payload,{ headers: { ExternalApiToken: payload.ExternalApiToken, ProductCode: payload.product_code} }).then((response) => {
        return response.data;
    }).catch((error) => {
        context.beingRegistered = false;
        dispatch('errorHandler',error.response.status);
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return error.response.data;
    })
}

export const loginToSSO = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/signin', payload).then((response) => {

        //set User details
        if(response.data.success){
            commit('setUser', {
                "email" : payload.email,
                "password" : payload.password,
                "keepMeLoggedIn": payload.keepMeLoggedIn,
                "sso_data":response.data.user,
                "profilePicUrl":response.data.profilePicUrl.profile_pic,
                "authtoken": response.data.token
            });
        }

        // Setting up auth token ad fetching user data
        let token = response.data.token;
        //localforage.removeItem("authtoken", '');
        //localforage.setItem("authtoken", token);
        //localStorage.setItem("localforage/smartartclient/authtoken", token);
        //setHttpToken(token);

        return response.data;
    }).catch((error) => {
        console.log(error);
        let message = error.response.data.message
        if (isEmpty(message)) {
            message = "Something Went Wrong"
        }
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', message, { root: true })
        context.isbeingLoggedIn = false
        if (!isEmpty(error.response.data.errors)) {
            context.errors = error.response.data.errors
        }
        dispatch('errorHandler',error.response.status);
        return false
    })
}

export const loginRESTApi = ({ commit, dispatch },payload) => {

    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/sign-in',payload.payload).then((response) => {
        try{
            if(response.data.success){
                localStorage.setItem("ls_data", JSON.stringify(response.data.user));
                // Showing  success message
                commit('SET_ALERT', 'success', { root: true })
                commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            }else{
                commit('setUser', {
                    "email" : payload.email,
                    "password" : '',
                    "keepMeLoggedIn": payload.keepMeLoggedIn,
                    "ls_data":'',
                    "profilePicUrl":''
                });
            }

            //dispatch('fetchUserDetails');

        }catch(error){
            console.log('Rest Login Api error',error);
            commit('setUser', {
                "email" : payload.email,
                "password" : '',
                "keepMeLoggedIn": payload.keepMeLoggedIn,
                "ls_data":'',
                "profilePicUrl":''
            });
        }

        return response.data;
    }).catch((error) => {
        dispatch('errorHandler',error.response.status);
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false
    });
}

export const fetchUserDetails = ({ commit, dispatch }, payload) => {
    const email = localStorage.getItem('email');
    let query = payload && payload.edit ? '?edit=true' : '';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/profile-details/'+email + query,{
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("localforage/smartartclient/authtoken")
        }
    }).then((response) => {
        //commit('setAuthenticated', true);
        //commit('setUserData', response.data)
        // commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH', null, { root: true })
        //dispatch('fetchBellNotifications')
        return response;
    }).catch((error) => {
        dispatch('errorHandler',error.response.status);
        return false;
    });
}

export const getUserAgreementSubscriptionData = ({ commit, dispatch },{payload, context}) => {
    let query = payload && payload.edit ? '?edit=true' : '';
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/getUserAgreementSubscriptionData'+query,payload,{
        headers: {
            //'Authorization': 'Bearer ' + localStorage.getItem("localforage/smartartclient/authtoken")
        }
        }).then((res) => {
            localStorage.setItem("ls_data", JSON.stringify(res.data.user));
            return res.data;
        }).catch((error) => {
            dispatch('errorHandler',error.response.status);
            return false;
        });
}

// -----------------------------------------------------------------------------------------------------------------------------------------------

export const forgotpassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/password/forgot-password', payload).then((response) => {
        if(response.data.success){
            let token = response.data.token;
            let email = response.data.email;
            const payloadForgotPassword = {
                token:token,
                email:email,
                fromAdmin: false,
                type : "seller"
            }
            return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/forgot-password', payloadForgotPassword).then((response) => {
                if(response.data.success == true){
                    commit('SET_ALERT', 'success', { root: true })
                    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                    return response;
                } else {
                    commit('SET_ALERT', 'warning', { root: true })
                    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                    return response;
                }
            }).catch((error) => {
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                context.errors = error.response.data.errors
                return false;
            })
        }
        return response.data.success
    }).catch((error) => {
        dispatch('errorHandler',error.response.status);
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'User Not found', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}

export const resetpassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/password/reset', payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        if(response.data.success){
            const activityLog = window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/activity-log-reset-password', payload).then((res) => {
                if(res.data.success){

                }
            }).catch((error) => {
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                context.errors = error.response.data.errors
                return false;
            })
        }
        return response;
    }).catch((error) => {
        console.log(error);
        if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else if(error.response.status == 422){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.password[0], { root: true })
            context.errors = error.response.data.errors
            return false;
        }else {
            dispatch('errorHandler',error.response.status);
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            context.errors = error.response.data.errors
            return false;
        }
    })
}

export const saveChangePassword = ({ commit, dispatch }, { payload, context }) => {
    const frmData = {
        old_password: payload.oldPassword,
        password: payload.newPassword,
        confirm_password: payload.reNewPassword
    };
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/change-password', frmData).then((response) => {
        if(response){
            commit('SET_ALERT', 'success', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            if(response.data.success){
                const activityLog = window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/activity-log-change-password', frmData).then((res) => {
                    if(res.data.success){
                        
                    }
                }).catch((error) => {
                    commit('SET_ALERT', 'warning', { root: true })
                    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                    context.errors = error.response.data.errors
                    return false;
                })
            }
            return response;
        }
    }).catch((error) => {
        console.log(error);
        if(error.response.status == 422){
            let laraErrors = error.response.data.errors; // this should be errors.
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', laraErrors.password, { root: true })
        } else if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else {
            dispatch('errorHandler',error.response.status);
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            context.errors = error.response.data.errors
            return false;
        }
    })
}
// -----------------------------------------------------------------------------------------------------------------------------------------------
export const saveConnectionDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/save-bucket-details', payload).then((response) => {
        if(response){
            return response.data;
        }
    }).catch((error) => {
        dispatch('errorHandler',error.response.status);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const signInUserlogout = ({commit, dispatch},{payload}) =>{
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/user-sign-in-logout',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch('errorHandler',error.response.status);
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    })
}

export const errorHandler = ({dispatch },payload) => {
    try{
        console.log('payload',payload);
        console.log('window.location.origin',window.location.origin);
        if(payload === 401){
            dispatch('logoutUnautorizedUser','');
            console.log('window.location.origin',window.location.origin);
            window.location.href = window.location.origin + "/sign-in";
        }
    }catch($e){
        console.log($e);
    }
}
export const logoutUnautorizedUser = (context,payload) => {
    let user = {
        "email" : localStorage.getItem('email'),
        "password" : localStorage.getItem('password'),
        "keepMeLoggedIn": JSON.parse(localStorage.getItem('keepMeLoggedIn')),
        "locale" : localStorage.getItem('localforage/smartartclient/locale')
    }
    try{
        localStorage.clear();
        localStorage.setItem("localforage/smartartclient/locale", user.locale);
        if(user && user.keepMeLoggedIn === true) {
            localStorage.setItem("email", user.email);
            localStorage.setItem("password", user.password);
            localStorage.setItem("keepMeLoggedIn", JSON.stringify(user.keepMeLoggedIn));
        }
    }catch($e){
        console.log($e);
    }
}