import { createStore } from 'vuex'
import state from "./state.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

import home from "../app/home/vuex/index.js";
//import search from "../app/search/vuex/index.js";
//import searchHeader from "../app/searchHeader/vuex/index.js";
import feedback from "../app/feedback/vuex/index.js";
import auth from "../app/auth/vuex/index.js";
//import gallery from "../app/gallery/vuex/index.js";
//import servies from "../app/servies/vuex/index.js";
//import crowdFunding from "../app/crowdFunding/vuex/index.js";
//import forum from "../app/forum/vuex/index.js";
//import cart from "../app/cart/vuex/index.js";
import dashboard from "../app/dashboard/vuex/index.js";
import myProfile from "../app/myProfile/vuex/index.js";
import myTasks from "../app/myTasks/vuex/index.js";
import orders from "../app/orders/vuex/index.js";
import artLibrary from "../app/artLibrary/vuex/index.js";
//import photoLibrary from "../app/photoLibrary/vuex/index.js";
//import frameLibrary from "../app/frameLibrary/vuex/index.js";
//import designLibrary from "../app/designLibrary/vuex/index.js";
import exhibitions from "../app/exhibitions/vuex/index.js";
import refferals from "../app/refferals/vuex/index.js";
import adminCrowdFunding from "../app/adminCrowdFunding/vuex/index.js";
import settings from "../app/settings/vuex/index.js";
import contactUs from "../app/contactUs/vuex/index.js";



export default createStore({
    state: {
        alerts: {
            default: false,
            success: false,
            warning: false,
            info: false,
            message: null
        },
        dkToastConfig: {
            success: {
                duration: 5000,
                positionX: 'center',
                positionY: 'top',
                styles: {
                    color: '#155724',
                    backgroundColor: '#d4edda',
                    borderColor: '#c3e6cb',
                    textAlign: 'left'
                }
            },
            error: {
                duration: 5000,
                positionX: 'center',
                positionY: 'top',
                styles: {
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb',
                    textAlign: 'left'
                }
            },
            warning: {
                duration: 5000,
                positionX: 'center',
                positionY: 'top',
                styles: {
                    color: '#856404',
                    backgroundColor: '#fff3cd',
                    borderColor: '#ffeeba',
                    textAlign: 'left'
                }
            }
        }
    },
    getters,
    mutations,
    actions,
    modules: {
        home: home,
        //search: search,
        //searchHeader: searchHeader,       
        feedback: feedback,       
        auth: auth,
        //gallery: gallery,
        //servies: servies,
        //crowdFunding: crowdFunding,
        //forum: forum,
        //cart: cart,
        dashboard: dashboard,
        myProfile: myProfile,
        myTasks: myTasks,
        orders: orders,
        artLibrary: artLibrary,
        //photoLibrary: photoLibrary,
        //frameLibrary: frameLibrary,
        //designLibrary: designLibrary,
        exhibitions: exhibitions,
        refferals: refferals,
        adminCrowdFunding: adminCrowdFunding,
        settings: settings,
        contactUs:contactUs
    },
});